
module.exports = {
    myHost: {
       
        // 'host':' http://127.0.0.1:8001',
    //    'host':'https://api-v2.smicee.com',
       'host':'https://api-digital.smicee.com',
    //    'host':'https://api-pixeltag.smicee.com',
    //    'host':'https://api-catchbd.smicee.com',

    }
};
